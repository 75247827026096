import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { NextUIProvider } from '@nextui-org/react';
import Home from './pages/_app';

function App() {
  return (
    // Wrap the app in a Router component
    <Router>
      <NextUIProvider>
        <Home />
      </NextUIProvider>
    </Router>
  );
}

export default App;
